<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <Toast
          v-if="toastData.show"
          :type="toastData.type"
          :message="toastData.message"
          :show="toastData.show"
          :time="10000"
          @clearToast="clearToast"
        />
        <div class="container-fluid">
          <!-- topbar -->
          <div
            class="d-flex justify-content-between my-2 mb-3"
            style="align-items: center;"
          >
            <div>
              <ul
                class="breadcrumb ProximaNovaRegular"
                style="margin-left: 10px"
              >
                <li>
                  <span>Solutions</span>
                </li>
                <li>
                  <router-link to="/solutions/health-monitor">
                    <span>Health Monitor</span>
                  </router-link>
                </li>
                <li class="ProximaNovaBold">{{ planName }}</li>
              </ul>
            </div>
            <div>
              <div>
                {{ startDate }} -
                {{ endDate }}
              </div>
              <date-picker
                v-model="customDate"
                class="date-picker"
                ref="datePicker"
                format="MMM YYYY"
                range
                confirm
                confirm-text="Done"
                :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></date-picker>
              <w-white-dropdown
                :options="filterDropdown"
                :placeHolder="'Select'"
                :selectedOption="selectedFilter"
                :labelText="''"
                @input="selectFilter($event)"
                width="178px"
              ></w-white-dropdown>
            </div>
          </div>
        </div>

        <div class="container-fluid first-row">
          <div class="row">
            <div class="cols-12 col-md-5">
              <div class="card" style="height: 100%">
                <div class="wrapper content-center px-3 pt-4">
                  <h5 class="card-heading">Objective: {{ objective }}</h5>
                </div>
                <div
                  class="content-center ProximaNovaSemiBold"
                  v-if="objStatus === 'error'"
                >
                  <div
                    @click="getObjective"
                    class="content-center refreshButton"
                  >
                    <span class="pr-3 font-medium">Retry</span
                    ><span class="refreshIcon"></span>
                  </div>
                </div>
                <DonutChart :chartData="objectiveData" ref="objectiveChart" />
              </div>
            </div>

            <div class="cols-12 col-md-7">
              <!-- CPA -->
              <cpa-box
                :cpaData="cpaData"
                @channelClicked="channelBox($event)"
                class="mb-4"
                :title="leveloneChannel"
                :noDataText="noDataText"
                :status="cpaStatus"
                @retry="getCPAChannelList"
              ></cpa-box>
              <!-- CPS -->
              <cpa-box
                :cpaData="cpcData"
                @channelClicked="channelBox($event)"
                :title="levelTwoChannel"
                :noDataText="noDataTextCPC"
                :status="cpcStatus"
                @retry="getCPCChannelList"
              ></cpa-box>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row mt-4">
            <!-- Performance -->
            <div class="col-12 col-lg-6">
              <div class="card pb-5 pb-lg-0 h-100">
                <div
                  class="wrapper content-center px-3 pt-4"
                  style="justify-content: space-between"
                >
                  <h5 class="card-heading">Performance</h5>
                  <div class="" style="cursor: pointer">
                    <w-multiDropDown
                      :initialSelectedOptions="selectedPerformace"
                      :options="performanceOptionList"
                      :maximum="4"
                      @selectedOptions="getSelectedPerformace($event)"
                      ref="performanceUpdate"
                    />
                  </div>
                </div>
                <div class="row perf-card-wrapper">
                  <div v-if="performanceData.length !== 0">
                    <div
                      v-for="(data, index) in performanceData"
                      :key="index"
                      class="col-12 col-md-6 d-flex justify-content-center py-3"
                    >
                      <div class="perf-card">
                        <Statistics
                          :data="data"
                          :index="index"
                          :specialChar="false"
                          :specialCharText="''"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-center font-medium w-100 ProximaNovaSemiBold"
                    v-html="performanceNoData"
                    v-else
                  ></div>
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="perfStatus === 'error'"
                  >
                    <div
                      @click="getPerformance"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Yesterday's Performance -->
            <div class="col-12 col-lg-6 mt-5 mt-lg-0">
              <div class="card h-100 overflow-auto">
                <div class="wrapper m-4">
                  <h5 class="card-heading">{{ endDate }} Performance</h5>
                  <div class="dropdown-wrapper">
                    <w-multiDropDown
                      :initialSelectedOptions="selectedYesterdayData"
                      :options="yesterdayDropList"
                      :maximum="5"
                      @selectedOptions="getSelectedYesterdaydata($event)"
                      ref="yesterdayUpdate"
                    />
                  </div>
                </div>
                <PerformanceTable
                  v-if="yesterdayPerformanceData.length !== 0"
                  class="mt-5"
                  :items="yesterdayPerformanceData"
                />
                <div
                  class="text-center content-center h-75 font-medium w-100 ProximaNovaSemiBold"
                  style="min-height:100px;"
                  v-else
                >
                  <div class="row w-100">
                    <div v-html="ystNoData.data"></div>
                    <div
                      class="content-center"
                      v-if="ystNoData.status === 'error'"
                    >
                      <div
                        @click="getYesterdayData"
                        class="content-center refreshButton"
                      >
                        <span class="pr-3">Retry</span
                        ><span class="refreshIcon"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Channel Performance -->
            <div class="col-12 col-md-12 mt-5">
              <div class="card h-100">
                <div class="wrapper m-4">
                  <h5 class="card-heading">Channel Performance</h5>
                  <div class="dropdown-wrapper">
                    <w-multiDropDown
                      :initialSelectedOptions="selectedChannelList"
                      :options="channelDropList"
                      :maximum="2"
                      @selectedOptions="getSelectedChannelList($event)"
                      ref="channelUpdate"
                    />
                  </div>
                </div>
                <div class="p-4">
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="channelPerfStatus === 'error'"
                  >
                    <div
                      @click="getChannelPerformaceData"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                  <TwoBarChart
                    :chartData="channelData"
                    :activeData="activeChannel"
                    @updateContent="updateChannel"
                    @updateIndex="updateChannelIndex"
                    ref="updateChannelPerformance"
                  />
                  <TwobarChartSummary
                    :rowData="channelBottomRowData"
                    :activeChannel="activeChannel"
                  />
                </div>
              </div>
            </div>
            <!-- Metrics -->
            <div class="col-12 col-md-12 mt-5">
              <div class="card h-100">
                <div class="wrapper m-4">
                  <h5 class="card-heading">Metrics By Duration</h5>
                  <div class="">
                    <w-multiDropDown
                      :initialSelectedOptions="selectedMetricList"
                      :options="metricDropList"
                      :maximum="2"
                      @selectedOptions="getSelectedMetricList($event)"
                      ref="metricUpdate"
                    />
                  </div>
                </div>
                <div class="p-4">
                  <div
                    class="content-center ProximaNovaSemiBold"
                    v-if="metricPerfStatus === 'error'"
                  >
                    <div
                      @click="getMerticData"
                      class="content-center refreshButton"
                    >
                      <span class="pr-3 font-medium">Retry</span
                      ><span class="refreshIcon"></span>
                    </div>
                  </div>
                  <TwoBarChart
                    :chartData="metricData"
                    :activeData="activeMetric"
                    @updateContent="updateMetric"
                    @updateIndex="updateMetricIndex"
                    ref="updateMetricData"
                  />
                  <TwobarChartSummary
                    :rowData="bottomRowData"
                    :activeChannel="activeMetric"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- CampaignType -->
          <div class="card mt-5 p-5">
            <div class="wrapper">
              <h5 class="card-heading">Campaign Type</h5>
              <div class="">
                <w-single-dropdown-radio
                  :options="campaignTypeDropList"
                  :value="selectedCampaignType"
                  @selectedOptions="getSelectedCampaignType($event)"
                />
              </div>
            </div>
            <b-container fluid>
              <div class="m-0 p-0 w-100">
                <CampaignType
                  v-if="campaignData.length !== 0"
                  :stripData="campaignData"
                  :widthBy="true"
                  @getDataBreakdown="getCampaignData"
                  :showLegend="showLegend"
                />
                <div
                  class="text-center font-medium w-100 ProximaNovaSemiBold"
                  v-html="cmapignLoad"
                  v-else
                ></div>
                <div
                  class="content-center ProximaNovaSemiBold"
                  v-if="camTypeStatus === 'error'"
                >
                  <div
                    @click="getCampaignTypeChartData"
                    class="content-center refreshButton"
                  >
                    <span class="pr-3 font-medium">Retry</span
                    ><span class="refreshIcon"></span>
                  </div>
                </div>
              </div>
            </b-container>
          </div>
          <div class="row p-0 m-0 col-12">
            <!-- Display -->
            <div class="col-6 p-0 m-0">
              <div class="mr-2">
                <div class="card mt-5 pt-4 px-0">
                  <div class="wrapper m-4">
                    <h5 class="card-heading">Display</h5>

                    <div class="">
                      <w-multiDropDown
                        :initialSelectedOptions="selectedDisplay"
                        :options="displayDropList"
                        :maximum="3"
                        @selectedOptions="getSelectedDisplay($event)"
                        ref="displayUpdate"
                      />
                    </div>
                  </div>
                  <b-container fluid>
                    <div
                      class="content-center ProximaNovaSemiBold"
                      v-if="displayStatus === 'error'"
                    >
                      <div
                        @click="getDisplayData"
                        class="content-center refreshButton"
                      >
                        <span class="pr-3 font-medium">Retry</span
                        ><span class="refreshIcon"></span>
                      </div>
                    </div>
                    <ColumnChart
                      :chartData="displayData"
                      ref="updateDisplayData"
                    />
                  </b-container>
                </div>
              </div>
            </div>
            <!-- Social -->
            <div class="col-6 p-0 m-0">
              <div class="ml-2 ">
                <div class="card pt-4 mt-5 px-0">
                  <div class="wrapper m-4">
                    <h5 class="card-heading">Social</h5>

                    <div class="">
                      <w-multiDropDown
                        :initialSelectedOptions="selectedSocial"
                        :options="socialDropList"
                        :maximum="3"
                        @selectedOptions="getSelectedSocial($event)"
                        ref="socialUpdate"
                      />
                    </div>
                  </div>
                  <b-container fluid>
                    <div
                      class="content-center ProximaNovaSemiBold"
                      v-if="socialStatus === 'error'"
                    >
                      <div
                        @click="getSocialData"
                        class="content-center refreshButton"
                      >
                        <span class="pr-3 font-medium">Retry</span
                        ><span class="refreshIcon"></span>
                      </div>
                    </div>
                    <ColumnChart
                      :chartData="socialData"
                      ref="updateSocialData"
                    />
                  </b-container>
                </div>
              </div>
            </div>
            <!-- Social Insights -->
            <div class="col-6 p-0 m-0 d-none">
              <div class="mr-2">
                <div class="card mt-5 pt-4 px-0">
                  <div class="wrapper m-4">
                    <h5 class="card-heading">Social Insights</h5>

                    <div class="">
                      <w-multiDropDown
                        :initialSelectedOptions="selectedSocialInsights"
                        :options="socialInsightsDropList"
                        :maximum="3"
                        @selectedOptions="getSelectedSocialInsights($event)"
                      />
                    </div>
                  </div>
                  <b-container fluid>
                    <ColumnChart
                      :chartData="socialInsightsData"
                      ref="updateSocialInsightsData"
                    />
                  </b-container>
                </div>
              </div>
            </div>
            <!-- Analytics -->
            <div class="col-6 p-0 m-0 d-none">
              <div class="ml-2 ">
                <div class="card mt-5 pt-4 px-0">
                  <div class="wrapper m-4">
                    <h5 class="card-heading">Analytics</h5>
                    <div class="">
                      <w-multiDropDown
                        :initialSelectedOptions="selectedAnalytics"
                        :options="analyticsDropList"
                        :maximum="3"
                        @selectedOptions="getSelectedAnalytics($event)"
                      />
                    </div>
                  </div>
                  <b-container fluid>
                    <ColumnChart
                      :chartData="analyticsData"
                      ref="updateAnalyticsData"
                    />
                  </b-container>
                </div>
              </div>
            </div>
          </div>

          <!-- Summary -->
          <b-container class="card mt-5 p-0" fluid>
            <div class="dropdown-wrapper">
              <w-multiDropDown-test
                :options="summaryOptionsSelected"
                @getSelectedHeader="getSelectedHeader"
                :maximum="maximum"
                :placeholder="'Show / Hide'"
                :showPlaceHoaderOnly="true"
              />
            </div>
            <h5 class="card-heading m-4">Summary</h5>
            <SummaryTable
              :items="summaryData"
              :extraHeader="extraHeader"
              :currentPage="currentPage"
              :perPage="perPage"
              :totalRows="totalRows"
              ref="updateHead"
              @getSelectedPageEvent="getSelectedPageEvent($event)"
            />
          </b-container>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import MultiDropDown from "@/widgets/MultiDropDown.vue";
import MultiDropDownTest from "@/widgets/MultiDropDownTest.vue";
import SummaryTable from "@/components/Table/SummaryTable.vue";
import CampaignType from "@/components/Solutions/CampaignType.vue";
import Statistics from "@/components/Statistics.vue";
import PerformanceTable from "@/components/Table/PerformanceTable.vue";
import SingleDropdownCheckbox from "@/widgets/SingleDropdownCheckbox.vue";
import DonutChart from "@/components/Chart/DonutChart.vue";
import TwoBarChart from "@/components/Chart/TwoBarChart.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
// import BarChart from "@/components/Chart/BarChart.vue";
import { HealthMonitor } from "@/services/HealthMonitorServices.js";
import CPABox from "@/components/HealthMonitor/CPABox.vue";
import Toast from "@/components/Toast/Toast.vue";
import { abbreviateNumber } from "js-abbreviation-number";
import DatePicker from "vue2-datepicker";
import moment from "moment";
const healthMonitor = new HealthMonitor();
import TwobarChartSummary from "@/widgets/TwobarchartSummary.vue";
import "@/mixins/CamelCase";

export default {
  props: [],
  components: {
    "w-white-dropdown": WhiteDropdown,
    "w-multiDropDown": MultiDropDown,
    "w-multiDropDown-test": MultiDropDownTest,
    "w-single-dropdown-radio": SingleDropdownCheckbox,
    DonutChart,
    TwobarChartSummary,
    CampaignType,
    SummaryTable,
    Toast,
    Statistics,
    PerformanceTable,
    DatePicker,
    TwoBarChart,
    ColumnChart,
    // BarChart,
    "cpa-box": CPABox,
  },
  data() {
    return {
      channelBottomRowData: [],
      bottomRowData: [
        {
          name: "Impression",
          value: "1.1K",
        },
        {
          name: "Conversion",
          value: "1.1K",
        },
        {
          name: "Cost",
          value: "1.1K",
        },
        {
          name: "CPC",
          value: "1.1K",
        },
      ],
      customDate: [new Date(), new Date()],
      errorTime: "",
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      campaignTypeDropList: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Engagements", value: "Engagements" },
        { name: "Video View", value: "Video_view" },
        { name: "Conversions", value: "conversions" },
        { name: "Conv Value", value: "Conv_value" },
      ],
      selectedCampaignType: { name: "Conversions", value: "conversions" },
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      leveloneChannel: "CPA",
      showLegend: false,
      levelTwoChannel: "",
      orgNumber: "",
      campaignId: "",
      planName: "",
      objective: "",
      startDate: "",
      endDate: "",
      cpaData: [],
      cpaStatus:'initial',
      noDataText: `<div><div class='Budgetloader'></div>Loading...</div>`,
      cpcData: [],
      cpcStatus:'initial',
      noDataTextCPC: `<div><div class='Budgetloader'></div>Loading...</div>`,
      selectedFilter: { text: "Date", id: "" },
      filterDropdown: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last 7 Days", id: "Last 7 Days" },
        { text: "Last 30 Days", id: "Last 30 Days" },
        { text: "This Month", id: "This Month" },
        { text: "Last Month", id: "Last Month" },
        { text: "This Year", id: "This Year" },
        { text: "Last Year", id: "Last Year" },
        { text: "Custom", id: "customSelect" },
      ],
      objStatus: "initial",
      objectiveData: {
        colors: [
          "#ffdd7c",
          "#faacfe",
          "#ffa2a2",
          "#b5a9ff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
          "#67e8ff",
        ],
        series: [
          {
            name: "",
            point: {
              events: {
                click: (data) => {
                  this.objective = data.point.name;
                  sessionStorage.setItem("Objective", data.point.name);
                  this.objectiveWiseData();
                },
              },
            },
            innerSize: "65%",
            data: [],
            showInLegend: true,
          },
        ],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              distance: -30,
              y: -5,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
              style: {
                fontWeight: "bold",
                color: "black",
                fontSize: "14px",
              },
            },
          },
        },
        legend: {
          layout: "vertical",
          align: "left",
          verticalAlign: "bottom",
          accessibility: {
            enabled: true,
          },
          symbolPadding: 10,
          symbolHeight: 20,
          itemMarginBottom: 24,
          itemStyle: {
            color: "#222a37",
            fontWeight: "bold",
            fontSize: "16px",
            padding: "24px",
          },
          enabled: true,
        },
      },
      performanceData: [],
      performanceNoData: `<div><div class='Budgetloader'></div>Loading...</div>`,
      perfStatus: "initial",
      yesterdayPerformanceData: [],
      ystNoData: {
        status: "initial",
        data: `<div><div class='Budgetloader'></div>Loading...</div>`,
      },
      channelPerformance: [
        {
          name: "Dainik Bhaskar",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Affilates",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Economic Times",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Facebook",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#1877f2",
          icon: "fb-icon.png",
        },
        {
          name: "Google Ads",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Twitter Ads",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#0077b5",
          icon: "google.png",
        },
        {
          name: "Instagram Ads",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#dd2a7b",
          icon: "instagram.svg",
        },
      ],
      metrics: [
        {
          name: "Dainik Bhaskar",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Affilates",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Economic Times",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Facebook",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#1877f2",
          icon: "fb-icon.png",
        },
        {
          name: "Google Ads",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#f77f00",
          icon: "google.png",
        },
        {
          name: "Twitter Ads",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#0077b5",
          icon: "google.png",
        },
        {
          name: "Instagram Ads",
          conversions: "11.3K",
          costs: "56K",
          cpc: "60.3",
          impressions: 13200002,
          backgroundColor: "#dd2a7b",
          icon: "instagram.svg",
        },
      ],
      channelPerfStatus: "initial",
      channelData: {
        startIndex: 0,
        endIndex: 7,
        height: "470",
        middleData: [],
        categories: [],
        colors: ["#b5a9ff", "#67e8ff"],
        series: [
          {
            name: "",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
      },
      metricPerfStatus: "initial",
      metricData: {
        startIndex: 0,
        endIndex: 7,
        height: "470",
        middleData: [],
        categories: [],
        colors: ["#b5a9ff", "#67e8ff"],
        series: [
          {
            name: "",
            data: [],
          },
          {
            name: "CPC",
            data: [],
          },
        ],
      },
      socialBarData: {
        height: 400,
        colors: [
          "#ffdd7c",
          "#b5a9ff",
          "#ffa2a2",
          "#fdbfff",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        xAxis: {
          categories: ["Conversions", "Clicks", "Engagements", "Costs"],
          title: {
            text: null,
          },
          labels: {
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
            labels: {
              style: {
                color: "#a9b6cd",
                fontSize: "12px",
                fontFamily: "ProximaNovaRegular",
              },
              formatter: function() {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            gridLineDashStyle: "shortdash",
            min: 0,
            max: 150,
            tickInterval: 25,
          },
        ],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false,
            },
            pointPadding: 0.3,
            colorByPoint: true,
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Social",
            data: [78, 60, 40, 50],
          },
        ],
      },
      analyticsData: {
        data: [
          {
            name: "Analytics",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#ffdd7c",
          "#faacfe",
          "#81f89e",
          "#ffa2a2",
        ],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
            pointPadding: 0.42,
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      displayStatus: "initial",
      displayData: {
        data: [
          {
            name: "Display",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#ffdd7c",
          "#faacfe",
          "#81f89e",
          "#ffa2a2",
        ],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
            pointPadding: 0.42,
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      socialInsightsData: {
        data: [
          {
            name: "Social Insights",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#ffdd7c",
          "#faacfe",
          "#81f89e",
          "#ffa2a2",
        ],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
            pointPadding: 0.42,
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      socialStatus: "initial",
      socialData: {
        data: [
          {
            name: "social",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#67e8ff",
          "#b5a9ff",
          "#ffdd7c",
          "#faacfe",
          "#81f89e",
          "#ffa2a2",
        ],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [
            "Conversions",
            "Clicks",
            "Engagements",
            "Costs",
            "Views",
            "Impressions",
          ],
          crosshair: {
            enabled: true,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineWidth: 0,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
            pointPadding: 0.42,
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      summaryData: [],
      activeChannel: "",
      activeMetric: "",
      campaignData: [],
      camTypeStatus: "initial",
      cmapignLoad: `<div><div class='Budgetloader'></div>Loading...</div>`,
      performanceInitialOptionsSelected: [
        { name: "Clicks", value: "click", $isDisabled: false },
        { name: "Conversions", value: "conversion", $isDisabled: false },
      ],
      performanceOptionList: [],
      selectedPerformace: [],
      yesterdayDropList: [
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Video Views", value: "Video_views", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
        // { name: "Cost_conv", value: "Cost Conv", $isDisabled: false },
        // { name: "Video View Rate", value: "Video_view", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        // { name: "CPL", value: "CPL", $isDisabled: false },
        { name: "CPA", value: "CPA", $isDisabled: false },
        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "CPV", value: "CPV", $isDisabled: false },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
        { name: "VTR", value: "VTR", $isDisabled: false },
      ],
      selectedYesterdayData: [],
      displayDropList: [
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Video View", value: "Video_view", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        // { name: "Cost Conv", value: "Cost_conv", $isDisabled: false },
        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_Rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
      ],
      selectedDisplay: [],
      socialDropList: [
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Video View", value: "Video_view", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
        // { name: "Cost_conv", value: "Cost_conv", $isDisabled: false },
        { name: "Video View Rate", value: "Video_view", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "CPV", value: "CPV", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "Page Likes", value: "page likes", $isDisabled: false },
        { name: "Reach", value: "reach", $isDisabled: false },
      ],
      selectedSocial: [],
      socialInsightsDropList: [
        { name: "Comments", value: "comments", $isDisabled: false },
        { name: "Impressions", value: "impressions", $isDisabled: false },
        { name: "Likes", value: "likes", $isDisabled: false },
        { name: "Clicks", value: "clicks", $isDisabled: false },
        { name: "Mentions", value: "mentions", $isDisabled: false },
        { name: "Engagements", value: "engagements", $isDisabled: false },
        { name: "Shares", value: "shares", $isDisabled: false },
        { name: "Video Views", value: "video views", $isDisabled: false },
      ],
      selectedSocialInsights: [
        { name: "Comments", value: "comments", $isDisabled: false },
        { name: "Impressions", value: "impressions", $isDisabled: false },
        { name: "Likes", value: "likes", $isDisabled: false },
      ],
      analyticsDropList: [
        { name: "New User", value: "newUser", $isDisabled: false },
        { name: "Sessions", value: "sessions", $isDisabled: false },
        { name: "Page Views", value: "pageViews", $isDisabled: false },
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Transactions", value: "transactions", $isDisabled: false },
        {
          name: "Transaction Revenue",
          value: "transactionRevenue",
          $isDisabled: false,
        },
      ],
      selectedAnalytics: [
        { name: "New User", value: "newUser", $isDisabled: false },
        { name: "Sessions", value: "sessions", $isDisabled: false },
        { name: "Page Views", value: "pageViews", $isDisabled: false },
      ],
      options: [
        { name: "Clicks", value: "click", $isDisabled: false },
        { name: "Conversions", value: "conversion", $isDisabled: false },
        { name: "CTR", value: "ctr", $isDisabled: false },
      ],
      channelDropList: [
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Video Views", value: "Video_views", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
        // { name: "Cost_conv", value: "Cost_conv", $isDisabled: false },
        // { name: "Video View Rate", value: "Video_view", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        // { name: "CPL", value: "CPL", $isDisabled: false },
        { name: "CPA", value: "CPA", $isDisabled: false },

        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "CPV", value: "CPV", $isDisabled: false },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
        { name: "VTR", value: "VTR", $isDisabled: false },
      ],
      selectedChannelList: [],
      metricDropList: [
        { name: "Impressions", value: "Impressions", $isDisabled: false },
        { name: "Clicks", value: "Clicks", $isDisabled: false },
        { name: "Video Views", value: "Video_views", $isDisabled: false },
        { name: "CTR", value: "CTR", $isDisabled: false },
        { name: "Conversions", value: "conversions", $isDisabled: false },
        { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
        { name: "Cost", value: "cost", $isDisabled: false },
        // { name: "Cost_conv", value: "Cost Conv", $isDisabled: false },
        // { name: "Video View Rate", value: "Video_view", $isDisabled: false },
        { name: "Engagements", value: "Engagements", $isDisabled: false },
        {
          name: "Engagements_rate",
          value: "Engagements_rate",
          $isDisabled: false,
        },
        // { name: "CPL", value: "CPL", $isDisabled: false },
        { name: "CPA", value: "CPA", $isDisabled: false },
        { name: "CPC", value: "CPC", $isDisabled: false },
        { name: "CPV", value: "CPV", $isDisabled: false },
        { name: "CPE", value: "CPE", $isDisabled: false },
        { name: "CPM", value: "CPM", $isDisabled: false },
        { name: "VTR", value: "VTR", $isDisabled: false },
      ],
      selectedMetricList: [],
      summaryOptionsSelected: [
        {
          name: "Channel",
          value: true,
          $isDisabled: true,
          key: "Channel",
        },
        {
          name: "Impressions",
          value: true,
          $isDisabled: false,
          key: "impressions",
        },
        { name: "CTR%", value: true, $isDisabled: false, key: "ctr" },
        { name: "Clicks", value: true, $isDisabled: false, key: "clicks" },
        { name: "CPC", value: true, $isDisabled: false, key: "cpc" },

        {
          name: "Conv Rate %",
          value: false,
          $isDisabled: false,
          key: "conversions_rate",
        },
        { name: "CPA", value: false, $isDisabled: false, key: "cpa" },
        { name: "CPE", value: false, $isDisabled: false, key: "cpe" },
        { name: "CPV", value: false, $isDisabled: false, key: "cpv" },
        {
          name: "Engagements Rate%",
          value: false,
          $isDisabled: false,
          key: "engagements_rate",
        },

        {
          name: "Conversions",
          value: true,
          $isDisabled: false,
          key: "conversions",
        },
        {
          name: "VideoViews",
          value: true,
          $isDisabled: false,
          key: "video_views",
        },
        {
          name: "Engagements",
          value: true,
          $isDisabled: false,
          key: "engagements",
        },
        { name: "Costs", value: true, $isDisabled: false, key: "cost" },
        { name: "CPM", value: true, $isDisabled: false, key: "cpm" },
      ],
      extraHeader: [],
      maximum: 4,
      channelRowData: [],
      metricRowData: [],
      dataStartDate: "",
      dataEndDate: "",
    };
  },
  computed: {
    orgnizeNumber() {
      return (data) => {
        if (data > 999) {
          return abbreviateNumber(data, 1, {
            symbols: ["", "k", "M", "B", "T", "P", "E"],
          });
        } else {
          return Math.round(data * 10) / 10;
        }
      };
    },
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > new Date();
    },
    selectFilter(e) {
      if (this.selectedFilter === e && e.text !== "Custom") {
        return;
      }
      this.selectedFilter = e;
      var startDateLocal;
      var endDateLocal;
      if (e && e.id === "customSelect") {
        this.$refs.datePicker.openPopup();
        return;
      } else if (e && e.id === "today") {
        startDateLocal = new Date();
        endDateLocal = new Date();
      } else if (e && e.id === "yesterday") {
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        startDateLocal = dateObj;
        endDateLocal = dateObj;
      } else if (e && e.id === "Last 7 Days") {
        startDateLocal = new Date(new Date().setDate(new Date().getDate() - 6));
        endDateLocal = new Date();
      } else if (e && e.id === "Last 30 Days") {
        startDateLocal = new Date(
          new Date().setDate(new Date().getDate() - 30)
        );
        endDateLocal = new Date();
      } else if (e && e.id === "This Month") {
        var today = new Date();
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 0, 1);
        endDateLocal = new Date();
      } else if (e && e.id === "Last Month") {
        today = new Date();
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDateLocal = new Date(today.getFullYear(), today.getMonth(), 0);
      } else if (e && e.id === "This Year") {
        today = new Date();
        startDateLocal = new Date(today.getFullYear(), 0, 1);
        endDateLocal = today;
      } else if (e && e.id === "Last Year") {
        today = new Date();
        startDateLocal = new Date(new Date().getFullYear(), -11, -30);
        endDateLocal = new Date(new Date().getFullYear(), 0, 0);
      }
      this.startDate = moment(startDateLocal).format("YYYY-MM-DD");
      this.endDate = moment(endDateLocal).format("YYYY-MM-DD");
      this.edited = true;
      sessionStorage.setItem("startDate", this.startDate);
      sessionStorage.setItem("endDate", this.endDate);
      sessionStorage.setItem(
        "selectedDate",
        JSON.stringify(this.selectedFilter)
      );
      this.customDate = [new Date(this.startDate), new Date(this.endDate)];
      this.objectiveWiseData();
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    errorToast(e) {
      clearTimeout(this.errorTime);
      var self = this;
      if (e === undefined) {
        this.errorTime = setTimeout(function() {
          self.toastData.show = true;
          self.toastData.type = "failed";
          self.toastData.message =
            "Something went wrong please try after sometime";
        }, 3000);
      } else {
        this.errorTime = setTimeout(function() {
          self.toastData.show = true;
          self.toastData.type = "failed";
          self.toastData.message = e;
        }, 3000);
      }
    },
    channelBox(e) {
      sessionStorage.setItem("channel", e.title);
      this.$router.push({
        name: "Channel Level Dashboard",
        params: { channel: e.title },
      });
    },
    updateChannel(data) {
      this.activeChannel = data;
      this.channelBottomRowData = this.channelRowData.find(
        (x) => x.name === data
      ).value;
    },
    updateChannelIndex(val) {
      this.channelData.startIndex = val.startIndex;
      this.channelData.endIndex = val.endIndex;
    },
    updateMetricIndex(val) {
      this.metricData.startIndex = val.startIndex;
      this.metricData.endIndex = val.endIndex;
    },
    updateMetric(data) {
      this.activeMetric = data;
      this.bottomRowData = this.metricRowData.find(
        (x) => x.name === data
      ).value;
    },
    getCampaignData(e) {
      sessionStorage.setItem("overalladType", e.label);
      sessionStorage.removeItem("channel");
      this.$router.push({
        name: "Overall AdTypeDSV",
        params: { overallAdType: e.label },
      });
    },
    getSelectedHeader(val) {
      this.extraHeader = val;
    },
    getObjective() {
      this.objStatus = "loading";
      this.objectiveData.series[0].data = [];
      this.$refs.objectiveChart.$refs.donutChart.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      var colors = ["#81f89e", "#ffa2a2", "#67e8ff", "#ffdd7c", "#b5a8ff"];
      healthMonitor
        .getObjective(
          this.orgNumber,
          this.campaignId,
          this.objective,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          this.$refs.objectiveChart.$refs.donutChart.chart.hideLoading();
          this.objectiveData.series[0].data = [];
          for (var i = 0; i < res.length; i++) {
            this.objectiveData.series[0].data.push({
              name: res[i].name,
              y: res[i].value,
              color: colors[i],
            });
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.objStatus = "error";
          this.$refs.objectiveChart.$refs.donutChart.chart.showLoading(
            e.message
          );
        });
    },
    getCPAChannelList() {
      this.noDataText = `<div><div class='Budgetloader'></div>Loading...</div>`;
      this.cpaStatus = 'loading'
      this.cpaData = [];
      healthMonitor
        .getChannelWiseMetrics(
          this.orgNumber,
          this.campaignId,
          this.leveloneChannel,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.noDataText = `<div>Loading...</div>`;
            this.cpaStatus = 'no data'
          }
          this.cpaData = [];

          for (var i = 0; i < res.length; i++) {
            this.cpaData.push({ title: res[i].name, value: res[i].value });
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.cpaStatus = 'error'
          this.noDataText = `<div>${e.message}</div>`;
        });
    },
    getCPCChannelList() {
      this.cpcData = [];
      this.cpcStatus = 'loading'
      this.noDataTextCPC = `<div><div class='Budgetloader'></div>Loading...</div>`;
      healthMonitor
        .getChannelWiseMetrics(
          this.orgNumber,
          this.campaignId,
          this.levelTwoChannel,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.noDataTextCPC = `<div>NA</div>`;
          }
          this.cpcData = [];
          for (var i = 0; i < res.length; i++) {
            this.cpcData.push({ title: res[i].name, value: res[i].value });
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.cpcStatus = 'error'
          this.noDataTextCPC = `<div>${e.message}</div>`;
        });
    },
    getSelectedPerformace(e) {
      this.selectedPerformace = e;
      this.getPerformance();
    },
    getPerformance() {
      var metric = [];
      for (var i = 0; i < this.selectedPerformace.length; i++) {
        metric.push(this.selectedPerformace[i].value);
      }
      this.performanceNoData = `<div><div class='Budgetloader'></div>Loading...</div>`;
      this.perfStatus = "loading";
      this.performanceData = [];
      healthMonitor
        .getSalesPerformance(
          this.orgNumber,
          this.campaignId,
          metric,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          this.performanceData = [];
          if (res.length === 0) {
            this.performanceNoData = `<div>NA</div>`;
            this.perfStatus = "no data";
          }
          var checkArray = [
            "View_rate",
            "Engagements_rate",
            "conversions_rate",
            "VTR",
            "CTR",
          ];
          for (var j = 0; j < res.length; j++) {
            if (checkArray.includes(res[j].name)) {
              var data = { label: "", value: "" };
              if (res[j].name === "conversions_rate") {
                data.label = "Convo Rate";
              } else {
                data.label = res[j].name + "%";
              }
              data.value = this.orgnizeNumber(res[j].value) + "%";

              this.performanceData.push(data);
            } else {
              this.performanceData.push({
                label: res[j].name,
                value: this.orgnizeNumber(res[j].value),
              });
            }
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.performanceNoData = `<div>${e.message}</div>`;
          this.perfStatus = "error";
        });
    },
    getSelectedYesterdaydata(e) {
      this.selectedYesterdayData = e;
      this.getYesterdayData();
    },
    getYesterdayData() {
      var metric = [];
      for (var k = 0; k < this.selectedYesterdayData.length; k++) {
        metric.push(this.selectedYesterdayData[k].value);
      }
      this.ystNoData = {
        status: "Loading",
        data: "<div><div class='Budgetloader'></div>Loading...</div>",
      };
      var colors = ["#81f89e", "#ffa2a2", "#67e8ff", "#ffdd7c", "#b5a8ff"];
      this.yesterdayPerformanceData = [];
      healthMonitor
        .getYesterdayPerformance(
          this.orgNumber,
          this.campaignId,
          metric,
          this.startDate,
          this.endDate,
          "",
          ""
        )
        .then((res) => {
          if (res.length === 0) {
            this.ystNoData = { status: "No Data", data: "<div>NA</div>" };
          }
          this.yesterdayPerformanceData = [];
          for (var i = 0; i < res.length; i++) {
            var differ = "";
            if (res[i].status === "Negative") {
              differ = "down";
            } else {
              differ = "up";
            }
            this.yesterdayPerformanceData.push({
              objectives: res[i].name,
              metricsDelivery: res[i].value,
              variance: {
                direction: differ,
                value: res[i].difference,
              },
              past10daysanalysis: [
                {
                  data: JSON.parse(res[i].data),
                  marker: {
                    symbol: "circle",
                    radius: 10,
                    status: {
                      hover: {
                        enabled: true,
                      },
                    },
                  },
                  type: "spline",
                  name: "",
                },
              ],
              categories: JSON.parse(res[i].dates),
              color: colors[i],
            });
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.ystNoData = { status: "error", data: `<div>${e.message}</div>` };
        });
    },
    getSelectedDisplay(e) {
      this.selectedDisplay = e;
      this.getDisplayData();
    },
    getDisplayData() {
      var metrics = [];
      for (var i = 0; i < this.selectedDisplay.length; i++) {
        metrics.push(this.selectedDisplay[i].value);
      }
      this.$refs.updateDisplayData.$refs.columnChart.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      this.displayStatus = "loading";
      this.displayData.xAxis.categories = [];
      this.displayData.data[0].data = [];
      healthMonitor
        .getDiplayMetrics(
          this.orgNumber,
          this.campaignId,
          metrics,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.$refs.updateDisplayData.$refs.columnChart.chart.showLoading(
              "No Data Found"
            );
            this.displayStatus = "No Data";
          } else {
            this.$refs.updateDisplayData.$refs.columnChart.chart.hideLoading();
          }
          this.displayData.xAxis.categories = [];
          this.displayData.data[0].data = [];
          for (var j = 0; j < res.length; j++) {
            this.displayData.xAxis.categories.push(res[j].name);
            this.displayData.data[0].data.push(res[j].value);
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.displayStatus = "error";
          this.$refs.updateDisplayData.$refs.columnChart.chart.showLoading(
            e.message
          );
        });
    },
    getSelectedSocial(e) {
      this.selectedSocial = e;
      this.getSocialData();
    },
    getSocialData() {
      var metrics = [];
      for (var i = 0; i < this.selectedSocial.length; i++) {
        metrics.push(this.selectedSocial[i].value);
      }
      this.socialData.data[0].data = [];
      this.socialData.xAxis.categories = [];
      this.socialStatus = "loading";
      this.$refs.updateSocialData.$refs.columnChart.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      healthMonitor
        .getSocialMetrics(
          this.orgNumber,
          this.campaignId,
          metrics,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.$refs.updateSocialData.$refs.columnChart.chart.showLoading(
              "No Data Found"
            );
            this.socialStatus = "No Data";
          } else {
            this.$refs.updateSocialData.$refs.columnChart.chart.hideLoading();
          }
          this.socialData.data[0].data = [];
          this.socialData.xAxis.categories = [];
          for (var j = 0; j < res.length; j++) {
            this.socialData.data[0].data.push(res[j].value);
            this.socialData.xAxis.categories.push(res[j].name);
          }
        })
        .catch((e) => {
          this.$refs.updateSocialData.$refs.columnChart.chart.showLoading(
            e.message
          );
          this.socialStatus = "error";
          this.errorToast(e.message);
        });
    },
    getSelectedSocialInsights(e) {
      this.selectedSocialInsights = e;
      this.getSocialInsights();
    },
    getSocialInsights() {
      var metrics = [];
      for (var i = 0; i < this.selectedSocialInsights.length; i++) {
        metrics.push(this.selectedSocialInsights[i].value);
      }
      this.socialInsightsData.data[0].data = [];
      this.socialInsightsData.xAxis.categories = [];
      this.$refs.updateSocialInsightsData.$refs.columnChart.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      healthMonitor
        .getSocialInsights(
          this.orgNumber,
          this.campaignId,
          metrics,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.$refs.updateSocialInsightsData.$refs.columnChart.chart.showLoading(
              "No Data Found"
            );
          } else {
            this.$refs.updateSocialInsightsData.$refs.columnChart.chart.hideLoading();
          }
          this.socialInsightsData.data[0].data = [];
          this.socialInsightsData.xAxis.categories = [];
          for (var j = 0; j < res.length; j++) {
            this.socialInsightsData.data[0].data.push(res[j].value);
            this.socialInsightsData.xAxis.categories.push(res[j].name);
          }
        })
        .catch((e) => {
          this.$refs.updateSocialInsightsData.$refs.columnChart.chart.showLoading(
            e.message
          );
          this.errorToast(e.message);
        });
    },
    getSelectedAnalytics(e) {
      this.selectedAnalytics = e;
      this.getAnalyticsData();
    },
    getAnalyticsData() {
      var metrics = [];
      for (var i = 0; i < this.selectedAnalytics.length; i++) {
        metrics.push(this.selectedAnalytics[i].value);
      }
      this.analyticsData.data[0].data = [];
      this.analyticsData.xAxis.categories = [];
      this.$refs.updateAnalyticsData.$refs.columnChart.chart.showLoading(
        "Loading"
      );
      healthMonitor
        .getAnalytics(
          this.orgNumber,
          this.campaignId,
          metrics,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.$refs.updateAnalyticsData.$refs.columnChart.chart.showLoading(
              "No Data Found"
            );
          } else {
            this.$refs.updateAnalyticsData.$refs.columnChart.chart.hideLoading();
          }
          this.analyticsData.data[0].data = [];
          this.analyticsData.xAxis.categories = [];
          for (var j = 0; j < res.length; j++) {
            this.analyticsData.data[0].data.push(res[j].value);
            this.analyticsData.xAxis.categories.push(res[j].name);
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.$refs.updateAnalyticsData.$refs.columnChart.chart.showLoading(
            e.message
          );
        });
    },
    getSelectedCampaignType(e) {
      this.selectedCampaignType = e;
      this.getCampaignTypeChartData();
    },
    getCampaignTypeChartData() {
      var colors = [
        "#b5a9ff",
        "#67e8ff",
        "#ffdd7c",
        "#faacfe",
        "#81f89e",
        "#b5a9ff",
        "#67e8ff",
        "#ffdd7c",
        "#faacfe",
        "#81f89e",
        "#b5a9ff",
        "#67e8ff",
        "#ffdd7c",
        "#faacfe",
        "#81f89e",
      ];
      this.cmapignLoad = `<div><div class='Budgetloader'></div>Loading...</div>`;
      this.campaignData = [];
      this.camTypeStatus = "loading";
      healthMonitor
        .getAdTypeWiseMetric(
          this.orgNumber,
          this.campaignId,
          this.selectedCampaignType.value,
          "",
          "",
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.length === 0) {
            this.cmapignLoad = `<div>NA</div>`;
            this.camTypeStatus = "no data";
          }
          this.campaignData = [];
          var total = 0;
          for (var j = 0; j < res.length; j++) {
            total += res[j].value;
          }
          if (res.length > 5) {
            this.showLegend = true;
          } else {
            this.showLegend = false;
          }
          for (var i = 0; i < res.length; i++) {
            var per = 0;
            var perValue = "";
            per = (res[i].value * 100) / total;
            if (per < 9) {
              perValue = "auto";
            } else {
              perValue = per + "%";
            }
            this.campaignData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: colors[i],
              percentage: perValue,
            });
          }
        })
        .catch((e) => {
          this.cmapignLoad = `<div>${e.message}</div>`;
          this.camTypeStatus = "error";
          this.errorToast(e.message);
        });
    },
    getSelectedChannelList(e) {
      this.selectedChannelList = e;
      this.getChannelPerformaceData();
    },
    getChannelPerformaceData() {
      this.$refs.updateChannelPerformance.$refs.barChart1.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      this.$refs.updateChannelPerformance.$refs.barChart2.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      var metric = [];
      for (var i = 0; i < this.selectedChannelList.length; i++) {
        metric.push(this.selectedChannelList[i].value);
      }
      this.channelPerfStatus = "loading";
      this.channelData.series[0].data = [];
      this.channelData.series[1].data = [];
      this.channelData.categories = [];
      this.channelData.middleData = [];
      this.channelRowData = [];
      this.channelBottomRowData = [];
      this.activeChannel = "";
      this.channelData.endIndex = 0;
      healthMonitor
        .getChannelPerformance(
          this.orgNumber,
          this.campaignId,
          metric,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          if (res.result.length === 0) {
            this.$refs.updateChannelPerformance.$refs.barChart1.chart.showLoading(
              "NA"
            );
            this.$refs.updateChannelPerformance.$refs.barChart2.chart.showLoading(
              "NA"
            );
            this.channelPerfStatus = "no data";
          } else {
            this.$refs.updateChannelPerformance.$refs.barChart1.chart.hideLoading();
            this.$refs.updateChannelPerformance.$refs.barChart2.chart.hideLoading();
          }
          this.channelData.categories = [];
          // this.channelData.categories = res.period;
          this.channelData.series[0].name = this.changeCase(res.value);
          this.channelData.series[1].name = this.changeCase(res.value2);
          var middleData = [];
          var category = [];
          this.channelData.series[0].data = [];
          this.channelData.series[1].data = [];
          this.channelRowData = [];
          if (res.result.length < 7) {
            this.channelData.endIndex = res.result.length;
          }else {
            this.channelData.endIndex = 7;
          }
          for (var i = 0; i < res.result.length; i++) {
            var data1 = [];
            var data2 = [];
            this.channelRowData.push({
              name: res.result[i].name,
              value: res.result[i].bottomrowData,
            });
            for (var j = 0; j < res.result[i].values.length; j++) {
              if (i === 0) {
                category.push(this.changeCase(res.result[i].values[j].name));
              }
              data1.push(Math.round(res.result[i].values[j].value * 10) / 10);
              data2.push(Math.round(res.result[i].values[j].value2 * 10) / 10);
            }
            this.channelData.series[0].data.push({
              name: res.result[i].name,
              data: data1,
            });
            this.channelData.series[1].data.push({
              name: res.result[i].name,
              data: data2,
            });
            middleData.push(res.result[i].name);
            if (i === res.result.length - 1) {
              this.channelData.middleData = middleData;
              this.channelData.categories = category;
              this.updateChannel(middleData[0]);
              this.$refs.updateChannelPerformance.updateData();
            }
          }
        })
        .catch((e) => {
          this.$refs.updateChannelPerformance.$refs.barChart1.chart.showLoading(
            e.message
          );
          this.$refs.updateChannelPerformance.$refs.barChart2.chart.showLoading(
            e.message
          );
          this.channelPerfStatus = "error";
          this.errorToast(e.message);
        });
    },
    getSelectedMetricList(e) {
      this.selectedMetricList = e;
      this.getMerticData();
    },
    getMerticData() {
      this.$refs.updateMetricData.$refs.barChart1.chart.showLoading(
        "<div><div class='Budgetloader'></div>Loading...</div>"
      );
      this.$refs.updateMetricData.$refs.barChart2.chart.showLoading(
        '<div><div class="Budgetloader"></div>Loading..</div>'
      );
      var metrics = [];
      this.metricPerfStatus = "loading";

      this.metricData.series[0].data = [];
      this.metricData.series[1].data = [];
      this.metricData.categories = [];
      this.metricData.middleData = [];
      this.metricRowData = [];
      this.metricData.endIndex = 0;
      this.bottomRowData = [];
      this.activeMetric = "";

      for (var i = 0; i < this.selectedMetricList.length; i++) {
        metrics.push(this.selectedMetricList[i].value);
      }
      healthMonitor
        .getMonthWisePerformance(
          this.orgNumber,
          this.campaignId,
          metrics,
          this.startDate,
          this.endDate
        )
        .then((res) => {
          this.metricData.categories = [];

          this.metricData.series[0].name = this.changeCase(res.value);
          this.metricData.series[1].name = this.changeCase(res.value2);
          var middleData = [];
          this.metricData.series[0].data = [];
          this.metricData.series[1].data = [];
          var category = [];
          this.metricRowData = [];
          if (res.result.length < 7) {
            this.metricData.endIndex = res.result.length;
          } else {
            this.metricData.endIndex = 7;
          }
          if (res.result.length === 0) {
            this.$refs.updateMetricData.$refs.barChart1.chart.showLoading("NA");
            this.$refs.updateMetricData.$refs.barChart2.chart.showLoading("NA");
            this.metricPerfStatus = "no data";
            return;
          } else {
            this.$refs.updateMetricData.$refs.barChart1.chart.hideLoading();
            this.$refs.updateMetricData.$refs.barChart2.chart.hideLoading();
          }
          this.metricData.categories = res.month;
          for (var i = 0; i < res.result.length; i++) {
            var data1 = [];
            var data2 = [];
            this.metricRowData.push({
              name: this.changeCase(res.result[i].name),
              value: res.result[i].bottomrowData,
            });
            for (var j = 0; j < res.result[i].values.length; j++) {
              if (i === 0) {
                category.push(this.changeCase(res.result[i].values[j].name));
              }
              data1.push(Math.round(res.result[i].values[j].value * 10) / 10);
              data2.push(Math.round(res.result[i].values[j].value2 * 10) / 10);
            }
            this.metricData.series[0].data.push({
              name: this.changeCase(res.result[i].name),
              data: data1,
            });
            this.metricData.series[1].data.push({
              name: this.changeCase(res.result[i].name),
              data: data2,
            });
            middleData.push(this.changeCase(res.result[i].name));
            if (i === res.result.length - 1) {
              this.metricData.categories = category;
              this.metricData.middleData = middleData;
              this.updateMetric(middleData[0]);
              this.$refs.updateMetricData.updateData();
            }
          }
        })
        .catch((e) => {
          this.$refs.updateMetricData.$refs.barChart1.chart.showLoading(
            e.message
          );
          this.$refs.updateMetricData.$refs.barChart2.chart.showLoading(
            e.message
          );
          this.metricPerfStatus = "error";
          this.errorToast(e.message);
        });
    },
    objectiveWiseData() {
      if (this.objective === "Conversions") {
        this.leveloneChannel = "CPA";
        this.levelTwoChannel = "CPC";
        this.performanceOptionList = [
          { name: "Cost", value: "cost", $isDisabled: false },
          {
            name: "Paid Conversions",
            value: "Paid_conversions",
            $isDisabled: false,
          },
          { name: "CPA", value: "CPA", $isDisabled: false },
          { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
          // {
          //   name: "Transaction Revenue",
          //   value: "Transaction_revenue",
          //   $isDisabled: true,
          // },
        ];
        this.selectedPerformace = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "Conv Rate%", value: "conversions_rate", $isDisabled: false },
          {
            name: "Paid Conversions",
            value: "Paid_conversions",
            $isDisabled: false,
          },
          { name: "CPA", value: "CPA", $isDisabled: false },
        ];
        this.selectedYesterdayData = [
          { name: "Conversions", value: "conversions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
        ];
        this.selectedDisplay = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Conversions", value: "conversions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedSocial = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Conversions", value: "conversions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedCampaignType = {
          name: "Conversions",
          value: "conversions",
        };
        this.selectedChannelList = [
          { name: "Conversions", value: "conversions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
        ];
        this.selectedMetricList = [
          { name: "Conversions", value: "conversions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
        ];
      } else if (this.objective === "Impressions") {
        this.leveloneChannel = "CPM";
        this.levelTwoChannel = "CTR";
        this.performanceOptionList = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "CPM", value: "CPM", $isDisabled: false },
        ];
        this.selectedPerformace = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "CPM", value: "CPM", $isDisabled: false },
        ];
        this.selectedYesterdayData = [
          { name: "CPM", value: "CPM", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
        ];
        this.selectedDisplay = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];

        this.selectedSocial = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];

        this.selectedCampaignType = {
          name: "Impressions",
          value: "Impressions",
        };

        this.selectedChannelList = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
        ];
        this.selectedMetricList = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
        ];
      } else if (this.objective === "Clicks") {
        this.leveloneChannel = "CPC";
        this.levelTwoChannel = "CPA";
        this.performanceOptionList = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: true },
          {
            name: "Organic Sessions",
            value: "Organic_sessions",
            $isDisabled: false,
          },
          { name: "Paid_clicks", value: "Paid_clicks", $isDisabled: false },
          { name: "CPC", value: "CPC", $isDisabled: false },
          { name: "Overall_cpc", value: "Overall_cpc", $isDisabled: true },
          { name: "Paid_cpc", value: "Paid_cpc", $isDisabled: true },
        ];
        this.selectedPerformace = [
          { name: "Cost", value: "cost", $isDisabled: false },
          {
            name: "Organic Sessions",
            value: "Organic_sessions",
            $isDisabled: false,
          },
          { name: "Paid_clicks", value: "Paid_clicks", $isDisabled: false },
          { name: "CPC", value: "CPC", $isDisabled: false },
        ];
        this.selectedYesterdayData = [
          { name: "CPC", value: "CPC", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "CTR", value: "CTR", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
        ];
        this.selectedDisplay = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
        ];
        this.selectedSocial = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
        ];
        this.selectedCampaignType = {
          name: "Clicks",
          value: "Clicks",
        };
        this.selectedChannelList = [
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
        ];
        this.selectedMetricList = [
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          { name: "Clicks", value: "Clicks", $isDisabled: false },
        ];
      } else if (this.objective.toLowerCase() === "Video Views".toLowerCase()) {
        this.leveloneChannel = "CPV";
        this.levelTwoChannel = "CPM";
        this.performanceOptionList = [
          { name: "Cost", value: "cost", $isDisabled: true },
          { name: "View_rate", value: "View_rate", $isDisabled: false },
          { name: "Video_views", value: "Video_views", $isDisabled: false },
          { name: "CPV", value: "CPV", $isDisabled: false },
          { name: "VTR", value: "VTR", $isDisabled: false },
          { name: "Overall_cpv", value: "Overall_cpv", $isDisabled: true },
          { name: "Paid", value: "Paid", $isDisabled: true },
          { name: "Paid_cpv", value: "Paid_cpv", $isDisabled: true },
        ];
        this.selectedPerformace = [
          { name: "View_rate", value: "View_rate", $isDisabled: false },
          { name: "Video_views", value: "Video_views", $isDisabled: false },
          { name: "CPV", value: "CPV", $isDisabled: false },
          { name: "VTR", value: "VTR", $isDisabled: false },
        ];
        this.selectedYesterdayData = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "Video Views", value: "Video_views", $isDisabled: false },
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "CPV", value: "CPV", $isDisabled: false },
          { name: "VTR", value: "VTR", $isDisabled: false },
        ];
        this.selectedDisplay = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Video View", value: "Video_view", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedSocial = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Video View", value: "Video_view", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedCampaignType = {
          name: "Video View",
          value: "Video_view",
        };
        this.selectedChannelList = [
          { name: "Video Views", value: "Video_views", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedMetricList = [
          { name: "Video Views", value: "Video_views", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
      } else if (this.objective === "Engagements") {
        this.leveloneChannel = "CPE";
        this.levelTwoChannel = "CTR";
        this.performanceOptionList = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          {
            name: "Engagements_rate",
            value: "Engagements_rate",
            $isDisabled: false,
          },
          { name: "CPE", value: "CPE", $isDisabled: false },
          { name: "Like", value: "Like", $isDisabled: true },
          { name: "CTR", value: "CTR", $isDisabled: true },
          { name: "Share", value: "Share", $isDisabled: true },
          { name: "Comments", value: "Comments", $isDisabled: true },
          { name: "Retweets", value: "Retweets", $isDisabled: true },
          { name: "CPE", value: "CPE", $isDisabled: true },
          { name: "Paid", value: "Paid", $isDisabled: true },
          { name: "Ovearll_cpe", value: "Ovearll_cpe", $isDisabled: true },
          { name: "Paid_cpe", value: "Paid_cpe", $isDisabled: true },
        ];
        this.selectedPerformace = [
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          {
            name: "Engagements_rate",
            value: "Engagements_rate",
            $isDisabled: false,
          },
          { name: "CPE", value: "CPE", $isDisabled: false },
        ];
        this.selectedYesterdayData = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Cost", value: "cost", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
          {
            name: "Engagements_rate",
            value: "Engagements_rate",
            $isDisabled: false,
          },
          { name: "CPE", value: "CPE", $isDisabled: false },
        ];
        this.selectedDisplay = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Video View", value: "Video_view", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedSocial = [
          { name: "Clicks", value: "Clicks", $isDisabled: false },
          { name: "Video View", value: "Video_view", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedCampaignType = {
          name: "Engagements",
          value: "Engagements",
        };
        this.selectedChannelList = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
        this.selectedMetricList = [
          { name: "Impressions", value: "Impressions", $isDisabled: false },
          { name: "Engagements", value: "Engagements", $isDisabled: false },
        ];
      }
      this.$refs.displayUpdate.updateData(this.selectedDisplay);
      this.$refs.channelUpdate.updateData(this.selectedChannelList);
      this.$refs.metricUpdate.updateData(this.selectedMetricList);
      this.$refs.performanceUpdate.updateData(this.selectedPerformace);
      this.$refs.socialUpdate.updateData(this.selectedSocial);
      this.$refs.yesterdayUpdate.updateData(this.selectedYesterdayData);
      this.callAllAPI();
    },
    getSelectedPageEvent(e) {
      if (e !== this.currentPage) {
        this.currentPage = e;
        this.getTableData();
      }
    },
    getTableData() {
      healthMonitor
        .getTableData(
          this.campaignId,
          this.orgNumber,
          "",
          "",
          "",
          this.startDate,
          this.endDate,
          this.currentPage,
          this.perPage
        )
        .then((res) => {
          this.summaryData = [];

          var data = JSON.parse(res.response);
          this.totalRows = data.total_element;
          this.summaryData = JSON.parse(data.content);
        })
        .catch((e) => {
          this.errorToast(e.message);
        });
    },
    callAllAPI() {
      this.getObjective();
      this.getCPAChannelList();
      this.getCPCChannelList();
      this.getPerformance();
      this.getYesterdayData();
      this.getDisplayData();
      this.getSocialData();
      this.getCampaignTypeChartData();
      this.getChannelPerformaceData();
      this.getMerticData();
      this.getSocialInsights();
      this.getAnalyticsData();
      this.getTableData();
    },
  },
  watch: {
    customDate(newVal) {
      if (newVal && newVal.length) {
        this.startDate = moment(newVal[0]).format("YYYY-MM-DD");
        this.endDate = moment(newVal[1]).format("YYYY-MM-DD");
        sessionStorage.setItem("endDate", this.endDate);
        sessionStorage.setItem("startDate", this.startDate);
        this.objectiveWiseData();
      }
    },
  },

  mounted() {
    this.dataStartDate = sessionStorage.getItem("dataStartDate");
    this.dataEndDate = sessionStorage.getItem("dataEndDate");
    this.planName = sessionStorage.getItem("planName");
    this.orgNumber = sessionStorage.getItem("subId");
    this.campaignId = sessionStorage.getItem("planId");
    this.objective = sessionStorage.getItem("Objective");
    this.startDate = sessionStorage.getItem("startDate");

    this.endDate = sessionStorage.getItem("endDate");
    this.customDate = [new Date(this.startDate), new Date(this.endDate)];
    this.selectedFilter = JSON.parse(sessionStorage.getItem("selectedDate"));
    this.objectiveWiseData();
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.first-row {
  background-color: #dfe3ea;
  padding-top: 20px;
  padding-bottom: 20px;
}
.date-picker {
  width: 150px;
  position: absolute;
  right: 12px;
}
.card-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.perf-card-wrapper {
  margin: 0px 24px;
}

.perf-card {
  width: 277px;
  height: 97px;
  box-shadow: 0 0 8px 0 #c9ced5;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-top: 44px;
}

.selected-details-card {
  padding: 8px 9px;
  box-shadow: 0 0 8px 0 #c9ced5;
  min-width: 174px;
  height: 63px;
}

.selected-details-icon-wrapper {
  height: 48px;
  width: 48px;
  border-radius: 3px;
}

.selected-details-name {
  font-family: ProximaNovaBold;
  color: #222a37;
  font-size: 16px;
  margin-left: 16px;
}

.selected-details {
  font-family: ProximaNovaRegular;
  color: #222a37;
  font-size: 16px;
  padding: 0 22px;
}
.selected-details-value {
  font-family: ProximaNovaBold;
}

.dropdown-wrapper {
  position: absolute;
  right: 10px;
  top: 12px;
}

.data {
  display: flex;
  overflow: hidden;

  width: 356px;
  height: 60px;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}

.dropdown {
  float: left;
  overflow: hidden;
  /* width: 32px;
  height: 20px; */
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #000000;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropmain {
  overflow: hidden;
  position: absolute;
  top: 34px;
  right: 20px;
}

.dropmain a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropbtn i {
  margin-left: 77px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  transform: rotate(-360deg);
}

.dropdownHead {
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  /* padding-right: 00px; */
  padding-left: 25px;
}

.dropdownCover_wrapper {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 34px;
  right: 20px;
}

.dropdown_coverprod {
  background-color: #eff5ff;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 20px;
  display: flex;
}

.dropdownHeadprod {
  /* padding-right: 00px; */
  /* padding-left: 25px; */
  width: 55px;
  height: 20px;
  margin: 10px 19px 20px 16px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.dropdownprod option {
  width: 92px;
  height: 20px;
  margin: 0 50px 68px 0;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}
.small-card-icon {
  height: 24px;
  width: 24px;
}
</style>
