<template>
  <div class="d-flex align-items-center">
    <div class="selected-details-card d-flex">
      <div
        class="selected-details-icon-wrapper d-flex align-items-center justify-content-center"
        v-if="!getImage(activeChannel).showtext"
      >
        <img
          class="small-card-icon"
          
          :src="getImage(activeChannel).url"
          :alt="activeChannel"
        />
      </div>
      <div class="d-flex align-items-center">
        <div class="selected-details-name">
          {{ activeChannel }}
        </div>
      </div>
    </div>
    <b-container class="ml-4">
      <b-row class="w-100">
        <div
          class="selected-details px-0 py-3 col-12 col-sm-6 col-md-3 col-lg-2 text-center "
          v-for="(data, index) in rowData"
          :key="index"
        >
          {{ changeCase(data.name) }}:
          <span class="selected-details-value">{{ orgnizeNumber(data.value) }}</span>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { abbreviateNumber } from "js-abbreviation-number";
import "@/mixins/CamelCase";
export default {
  props: {
    activeChannel: { type: String, default: "" },
    rowData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getImage(image) {
      try {
        return {
          url: require("@/assets/connectors/" + image + ".svg"),
          showtext: false,
        };
      } catch (error) {
        if (error.code === "MODULE_NOT_FOUND") {
          try {
            return {
              url: require("@/assets/connectors/" + image + ".jpg"),
              showtext: false,
            };
          } catch (error) {
            if (error.code === "MODULE_NOT_FOUND") {
              try {
                return {
                  url: require("@/assets/connectors/" + image + ".png"),
                  showtext: false,
                };
              } catch {
                return { url: image, showtext: true };
              }
            }
            return { url: image, showtext: true };
          }
        }
        return { url: image, showtext: true };
      }
    },
  },
  computed:{
    orgnizeNumber(){
      return (data)=>{
        if(data<999){
          return Math.round(data)
        }else{
          return abbreviateNumber(data,1,{symbols: ["", "k", "M", "B", "T", "P", "E"]})
        }
      }
    }
  }
};
</script>

<style scoped>
.selected-details-name {
  font-family: ProximaNovaBold;
  color: #222a37;
  font-size: 16px;
  margin-left: 16px;
}

.selected-details {
  font-family: ProximaNovaRegular;
  color: #222a37;
  font-size: 16px;
  padding: 0 22px;
}
.selected-details-value {
  font-family: ProximaNovaBold;
}
.selected-details-card {
  padding: 8px 9px;
  box-shadow: 0 0 8px 0 #c9ced5;
  min-width: 174px;
  height: 63px;
}

.selected-details-icon-wrapper {
  height: 48px;
  width: 48px;
  border-radius: 3px;
}
.small-card-icon{
    width: inherit;
}

</style>
